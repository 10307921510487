import type { AxiosError } from 'axios'
import axios from 'axios'
import { endOfDay, isSameDay, startOfDay } from 'date-fns'
import type { DateRange } from 'react-day-picker'
import { z } from 'zod'

import { env } from '~/env.mjs'
import { getAccessToken } from '~/features/auth/use-user'
import type { SortByOption } from '~/features/filters/sort-by-filter'
import exampleOffer from '~/features/inventory/waiting-room/example-offer.json'
import type {
  Configuration,
  Configurations,
  ConfigurationType,
} from '~/schema/configuration'
import { type UnitItem, UnitItemSchema } from '~/schema/unit-item'
import type { UnitItems } from '~/schema/unit-items'
import { UnitItemsSchema } from '~/schema/unit-items'
import type { Unit, Units } from '~/schema/units'
import { UnitsSchema } from '~/schema/units'

import type { Activity } from './activity'
import { ActivitySchema } from './activity'
import type { Category } from './category'
import type { Condition } from './condition'
import type { Languages } from './languages'
import { LanguagesSchema } from './languages'
import { type Market } from './markets'
import type { TotalProductsByUnit } from './total-products-by-unit'
import { TotalProductsByUnitSchema } from './total-products-by-unit'
import type { Users } from './user'

export const api = {
  axiosInstance: axios.create({
    baseURL: `${env.NEXT_PUBLIC_API_ROOT}`,
  }),
  markets: {
    get: async () => {
      const response = await api.axiosInstance.get<Market[]>(`/markets`, {
        headers: {
          Accept: `application/vnd.ingka.v2+json`,
        },
      })
      return response.data
    },
  },
  authentication: {
    authenticate: async (
      headersOptions: { Authorization?: string; 'x-client-id': string },
      data?: { code?: string; redirectUrl: string },
    ) => {
      try {
        return api.axiosInstance.post(`/auth/login`, data, {
          headers: { ...headersOptions },
        })
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError
          return Promise.reject(axiosError.message)
        } else {
          return Promise.reject('Unknown error')
        }
      }
    },
    refresh: async () => {
      return api.axiosInstance.post(`/auth/refresh`, undefined, {
        headers: { Authorization: getAccessToken() },
      })
    },
  },
  configuration: {
    get: async ({
      countryCode,
      languageCode,
      type,
    }: {
      countryCode: string
      languageCode: string
      type: ConfigurationType
    }) => {
      const url = `/api/${type}/config/${countryCode}/${languageCode}`
      const response = await api.axiosInstance.get<Configurations>(url, {
        headers: { Authorization: getAccessToken() },
      })
      return response.data
    },
  },
  coworker: {
    item: {
      get: async ({
        offerId,
        isTourOpen = false,
      }: {
        offerId: string
        isTourOpen?: boolean
      }) => {
        const url = `/api/coworker/item/${offerId}`
        if (isTourOpen) {
          // @ts-ignore this mock needs an update
          return exampleOffer as UnitItem
        }
        const response = await api.axiosInstance.get(url, {
          headers: { Authorization: getAccessToken() },
        })
        try {
          return UnitItemSchema.parse(response.data)
        } catch (error) {
          console.error(error)
          return response.data as UnitItem
        }
      },
    },
    languages: {
      get: async ({ countryCode }: { countryCode: string }) => {
        const response = await api.axiosInstance.get(
          `/api/coworker/languages/${countryCode}`,
          { headers: { Authorization: getAccessToken() } },
        )

        try {
          return LanguagesSchema.parse(response.data)
        } catch (error) {
          console.error(error)
          return response.data as Languages
        }
      },
    },
    offer: {
      delete: async (sekundId: string) => {
        return api.axiosInstance.delete<UnitItem>(`/offers/${sekundId}`, {
          headers: { Authorization: getAccessToken() },
        })
      },
      post: async (offer: UnitItem) => {
        return api.axiosInstance.post<UnitItem>(
          `/api/coworker/offer/edit`,
          offer,
          { headers: { Authorization: getAccessToken() } },
        )
      },
      logs: {
        get: async ({ itemId }: { itemId: string }) => {
          const response = await api.axiosInstance.get<Activity[]>(
            `/api/coworker/offer/logs/${itemId}`,
            { headers: { Authorization: getAccessToken() } },
          )
          return z.array(ActivitySchema).parse(response.data)
        },
      },
    },
    reasonsForDiscount: {
      get: async ({
        countryCode,
        languageCode,
      }: {
        countryCode: string
        languageCode: string
      }) => {
        const url = `${env.NEXT_PUBLIC_API_ROOT}/api/coworker/reasons-for-discount/${countryCode}/${languageCode}`
        const response = await fetch(url, {
          headers: { Authorization: getAccessToken() },
        })
        const reasonsDiscount = await response.json()
        return reasonsDiscount
      },
    },
    conditions: {
      get: async ({
        countryCode,
        languageCode,
      }: {
        countryCode: string
        languageCode: string
      }) => {
        const response = await api.axiosInstance.get<Condition[]>(
          `/product-conditions/${countryCode}/${languageCode}`,
          {
            headers: { Authorization: getAccessToken() },
          },
        )
        return response.data
      },
    },
    units: {
      get: async ({ countryCode }: { countryCode: string }) => {
        const url = `/api/coworker/units/${countryCode}`
        const response = await api.axiosInstance.get(url.toString(), {
          headers: { Authorization: getAccessToken() },
        })
        try {
          return UnitsSchema.parse(response.data)
        } catch (error) {
          console.error(error)
          return response.data as Units
        }
      },
    },
    unit: {
      post: async (store: Partial<Unit>) => {
        return api.axiosInstance.post<Unit>(`/api/units`, store, {
          headers: { Authorization: getAccessToken() },
        })
      },
    },
  },
  offers: {
    get: async ({
      unitId,
      page = 0,
      states,
      size,
      countryCode,
      languageCode,
      searchValue,
      sortBy,
      dateRange,
    }: {
      token?: string
      unitId: string
      page?: number
      size: number
      states: UnitItem['state'][]
      countryCode?: string
      languageCode: string
      searchValue?: string
      sortBy: SortByOption
      dateRange?: DateRange
    }) => {
      if (!countryCode) throw new Error('countryCode is required')
      const storeUrl = new URL(
        `${env.NEXT_PUBLIC_API_ROOT}/api/offers/${countryCode}/${languageCode}/${unitId}`,
      )
      if (states) storeUrl.searchParams.append('states', states.join(','))
      storeUrl.searchParams.append('size', size.toString())
      storeUrl.searchParams.append('myItems', 'false')
      storeUrl.searchParams.append('page', page.toString())
      if (dateRange?.from) {
        if (!dateRange.to || isSameDay(dateRange.from, dateRange.to)) {
          storeUrl.searchParams.append(
            'dateRange',
            `${startOfDay(dateRange.from).getTime()},${endOfDay(
              dateRange.from,
            ).getTime()}`,
          )
        } else {
          storeUrl.searchParams.append(
            'dateRange',
            `${dateRange.from.getTime()},${endOfDay(dateRange.to).getTime()}`,
          )
        }
      } else {
        storeUrl.searchParams.append(
          'dateRange',
          `${startOfDay(new Date(0)).getTime()},${endOfDay(
            new Date(),
          ).getTime()}`,
        )
      }
      const [column, direction] = sortBy.split('-')
      storeUrl.searchParams.append('column', column)
      storeUrl.searchParams.append('direction', direction)
      if (searchValue) {
        storeUrl.searchParams.append('searchCode', searchValue)
      }

      const response = await fetch(decodeURIComponent(storeUrl.toString()), {
        headers: { Authorization: getAccessToken() },
      })
      const data = await response.json()

      try {
        return UnitItemsSchema.parse(data)
      } catch (error) {
        console.error(error)
        return data as UnitItems
      }
    },
    getTotalProductsByUnit: async ({ unitId }: { unitId?: string }) => {
      const response = await api.axiosInstance.get<TotalProductsByUnit>(
        `/offers/${unitId}/count`,
        {
          headers: { Authorization: getAccessToken() },
        },
      )
      try {
        return TotalProductsByUnitSchema.parse(response.data)
      } catch (error) {
        console.error(error)
        return response.data as TotalProductsByUnit
      }
    },
  },
  owner: {
    configurations: {
      post: async (
        input?: Configuration,
        country_code?: string,
        lang_code?: string,
      ) => {
        return api.axiosInstance.post(
          `/api/owner/config/save`,
          [
            {
              ...input,
              country_code,
              lang_code,
            },
          ],
          { headers: { Authorization: getAccessToken() } },
        )
      },
    },
    users: {
      get: async ({
        page = 0,
        size,
        countryCode,
        searchValue,
      }: {
        page?: number
        size: number
        countryCode: string
        searchValue?: string
      }) => {
        const usersUrl = new URL(`${env.NEXT_PUBLIC_API_ROOT}/api/owner/users`)
        usersUrl.searchParams.append('country_code', countryCode)
        usersUrl.searchParams.append('size', size.toString())
        usersUrl.searchParams.append('page', page.toString())
        if (searchValue && searchValue?.trim() !== '')
          usersUrl.searchParams.append('search', searchValue)
        return api.axiosInstance.get<Users>(
          decodeURIComponent(usersUrl.toString()),
          { headers: { Authorization: getAccessToken() } },
        )
      },
    },
    user: {
      post: async ({ code, userId }: { code: string; userId: string }) => {
        const url = decodeURIComponent(
          new URL(`${env.NEXT_PUBLIC_API_ROOT}/api/owner/user`).toString(),
        )

        return api.axiosInstance.post<{}>(
          url,
          { code, userId },
          { headers: { Authorization: getAccessToken() } },
        )
      },

      delete: async ({ code, userId }: { code: string; userId: string }) => {
        const url = decodeURIComponent(
          new URL(`${env.NEXT_PUBLIC_API_ROOT}/api/owner/user`).toString(),
        )

        return api.axiosInstance.delete<{}>(url, {
          data: { code, userId },
          headers: { Authorization: getAccessToken() },
        })
      },
    },
  },
  public: {
    categories: {
      get: async ({
        countryCode,
        languageCode,
      }: {
        countryCode: string
        languageCode: string
      }) => {
        const response = await api.axiosInstance.get<Category[]>(
          `/api/public/categories/${countryCode}/${languageCode}`,
        )
        return response.data
      },
    },
  },
}
